/* This file is marked as side effect in package.json to be included by SB  */
@import 'ayvensFonts.css';

* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  min-height: 100%;
  background-color: #f6f8fb;
  color: #2d2d2d;
  overflow-y: scroll;
}

html,
body {
  margin: 0;
  min-width: 320px;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ot-sdk-container {
  margin-left: 0 !important;
}
