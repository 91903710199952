@import url('https://fonts.leaseplancdn.com/v1/ayvens/fonts.css');

body {
  font-weight: var(--vel-typography-text-300-font_weight);
  font-size: var(--vel-typography-text-300-font_size);
  line-height: var(--vel-typography-text-300-line_height);
  font-family: var(--vel-typography-text-300-font_family);
  letter-spacing: var(--vel-typography-text-300-letter_spacing);
  color: rgb(15, 53, 73);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
:root {
  --vel-zindex-dropdown: 10;
  --vel-zindex-top_navigation: 1000;
  --vel-zindex-tooltip: 1100;
  --vel-zindex-drawer: 1200;
  --vel-zindex-dropdown-inside-drawer: 1210;
  --vel-zindex-modal: 1300;
  --vel-zindex-dropdown-inside-modal: 1310;
  --vel-zindex-toaster: 1400;
  --vel-zindex-grid_util: 10000;
}
:root {
  --vel-breakpoint-xs: 0px;
  --vel-breakpoint-sm: 480px;
  --vel-breakpoint-md: 740px;
  --vel-breakpoint-lg: 960px;
  --vel-breakpoint-xl: 1278px;
  --vel-breakpoint-xxl: 1600px;
}
:root {
  --vel-fixed-spacing-01: 2px;
  --vel-fixed-spacing-02: 4px;
  --vel-fixed-spacing-03: 8px;
  --vel-fixed-spacing-04: 12px;
  --vel-fixed-spacing-05: 16px;
  --vel-fixed-spacing-06: 20px;
  --vel-fixed-spacing-07: 24px;
  --vel-fixed-spacing-08: 32px;
  --vel-fixed-spacing-09: 40px;
  --vel-layout-spacing-01: 4px;
  --vel-layout-spacing-02: 8px;
  --vel-layout-spacing-03: 12px;
  --vel-layout-spacing-04: 16px;
  --vel-layout-spacing-05: 24px;
  --vel-layout-spacing-06: 32px;
  --vel-layout-spacing-07: 48px;
  --vel-layout-spacing-08: 64px;
}
@media only screen and (min-width: 740px) {
  :root {
    --vel-layout-spacing-01: 4px;
    --vel-layout-spacing-02: 8px;
    --vel-layout-spacing-03: 16px;
    --vel-layout-spacing-04: 24px;
    --vel-layout-spacing-05: 32px;
    --vel-layout-spacing-06: 48px;
    --vel-layout-spacing-07: 64px;
    --vel-layout-spacing-08: 96px;
  }
}
.vel-margin-top-01.vel-margin-top-01 {
  -webkit-margin-before: var(--vel-fixed-spacing-01);
          margin-block-start: var(--vel-fixed-spacing-01);
}
.vel-margin-bottom-01.vel-margin-bottom-01 {
  -webkit-margin-after: var(--vel-fixed-spacing-01);
          margin-block-end: var(--vel-fixed-spacing-01);
}
.vel-margin-y-01.vel-margin-y-01 {
  margin-block: var(--vel-fixed-spacing-01);
}
.vel-margin-start-01.vel-margin-start-01 {
  -webkit-margin-start: var(--vel-fixed-spacing-01);
          margin-inline-start: var(--vel-fixed-spacing-01);
}
.vel-margin-end-01.vel-margin-end-01 {
  -webkit-margin-end: var(--vel-fixed-spacing-01);
          margin-inline-end: var(--vel-fixed-spacing-01);
}
.vel-margin-x-01.vel-margin-x-01 {
  margin-inline: var(--vel-fixed-spacing-01);
}
.vel-margin-around-01.vel-margin-around-01 {
  margin: var(--vel-fixed-spacing-01);
}
.vel-margin-top-02.vel-margin-top-02 {
  -webkit-margin-before: var(--vel-fixed-spacing-02);
          margin-block-start: var(--vel-fixed-spacing-02);
}
.vel-margin-bottom-02.vel-margin-bottom-02 {
  -webkit-margin-after: var(--vel-fixed-spacing-02);
          margin-block-end: var(--vel-fixed-spacing-02);
}
.vel-margin-y-02.vel-margin-y-02 {
  margin-block: var(--vel-fixed-spacing-02);
}
.vel-margin-start-02.vel-margin-start-02 {
  -webkit-margin-start: var(--vel-fixed-spacing-02);
          margin-inline-start: var(--vel-fixed-spacing-02);
}
.vel-margin-end-02.vel-margin-end-02 {
  -webkit-margin-end: var(--vel-fixed-spacing-02);
          margin-inline-end: var(--vel-fixed-spacing-02);
}
.vel-margin-x-02.vel-margin-x-02 {
  margin-inline: var(--vel-fixed-spacing-02);
}
.vel-margin-around-02.vel-margin-around-02 {
  margin: var(--vel-fixed-spacing-02);
}
.vel-margin-top-03.vel-margin-top-03 {
  -webkit-margin-before: var(--vel-fixed-spacing-03);
          margin-block-start: var(--vel-fixed-spacing-03);
}
.vel-margin-bottom-03.vel-margin-bottom-03 {
  -webkit-margin-after: var(--vel-fixed-spacing-03);
          margin-block-end: var(--vel-fixed-spacing-03);
}
.vel-margin-y-03.vel-margin-y-03 {
  margin-block: var(--vel-fixed-spacing-03);
}
.vel-margin-start-03.vel-margin-start-03 {
  -webkit-margin-start: var(--vel-fixed-spacing-03);
          margin-inline-start: var(--vel-fixed-spacing-03);
}
.vel-margin-end-03.vel-margin-end-03 {
  -webkit-margin-end: var(--vel-fixed-spacing-03);
          margin-inline-end: var(--vel-fixed-spacing-03);
}
.vel-margin-x-03.vel-margin-x-03 {
  margin-inline: var(--vel-fixed-spacing-03);
}
.vel-margin-around-03.vel-margin-around-03 {
  margin: var(--vel-fixed-spacing-03);
}
.vel-margin-top-04.vel-margin-top-04 {
  -webkit-margin-before: var(--vel-fixed-spacing-04);
          margin-block-start: var(--vel-fixed-spacing-04);
}
.vel-margin-bottom-04.vel-margin-bottom-04 {
  -webkit-margin-after: var(--vel-fixed-spacing-04);
          margin-block-end: var(--vel-fixed-spacing-04);
}
.vel-margin-y-04.vel-margin-y-04 {
  margin-block: var(--vel-fixed-spacing-04);
}
.vel-margin-start-04.vel-margin-start-04 {
  -webkit-margin-start: var(--vel-fixed-spacing-04);
          margin-inline-start: var(--vel-fixed-spacing-04);
}
.vel-margin-end-04.vel-margin-end-04 {
  -webkit-margin-end: var(--vel-fixed-spacing-04);
          margin-inline-end: var(--vel-fixed-spacing-04);
}
.vel-margin-x-04.vel-margin-x-04 {
  margin-inline: var(--vel-fixed-spacing-04);
}
.vel-margin-around-04.vel-margin-around-04 {
  margin: var(--vel-fixed-spacing-04);
}
.vel-margin-top-05.vel-margin-top-05 {
  -webkit-margin-before: var(--vel-fixed-spacing-05);
          margin-block-start: var(--vel-fixed-spacing-05);
}
.vel-margin-bottom-05.vel-margin-bottom-05 {
  -webkit-margin-after: var(--vel-fixed-spacing-05);
          margin-block-end: var(--vel-fixed-spacing-05);
}
.vel-margin-y-05.vel-margin-y-05 {
  margin-block: var(--vel-fixed-spacing-05);
}
.vel-margin-start-05.vel-margin-start-05 {
  -webkit-margin-start: var(--vel-fixed-spacing-05);
          margin-inline-start: var(--vel-fixed-spacing-05);
}
.vel-margin-end-05.vel-margin-end-05 {
  -webkit-margin-end: var(--vel-fixed-spacing-05);
          margin-inline-end: var(--vel-fixed-spacing-05);
}
.vel-margin-x-05.vel-margin-x-05 {
  margin-inline: var(--vel-fixed-spacing-05);
}
.vel-margin-around-05.vel-margin-around-05 {
  margin: var(--vel-fixed-spacing-05);
}
.vel-margin-top-06.vel-margin-top-06 {
  -webkit-margin-before: var(--vel-fixed-spacing-06);
          margin-block-start: var(--vel-fixed-spacing-06);
}
.vel-margin-bottom-06.vel-margin-bottom-06 {
  -webkit-margin-after: var(--vel-fixed-spacing-06);
          margin-block-end: var(--vel-fixed-spacing-06);
}
.vel-margin-y-06.vel-margin-y-06 {
  margin-block: var(--vel-fixed-spacing-06);
}
.vel-margin-start-06.vel-margin-start-06 {
  -webkit-margin-start: var(--vel-fixed-spacing-06);
          margin-inline-start: var(--vel-fixed-spacing-06);
}
.vel-margin-end-06.vel-margin-end-06 {
  -webkit-margin-end: var(--vel-fixed-spacing-06);
          margin-inline-end: var(--vel-fixed-spacing-06);
}
.vel-margin-x-06.vel-margin-x-06 {
  margin-inline: var(--vel-fixed-spacing-06);
}
.vel-margin-around-06.vel-margin-around-06 {
  margin: var(--vel-fixed-spacing-06);
}
.vel-margin-top-07.vel-margin-top-07 {
  -webkit-margin-before: var(--vel-fixed-spacing-07);
          margin-block-start: var(--vel-fixed-spacing-07);
}
.vel-margin-bottom-07.vel-margin-bottom-07 {
  -webkit-margin-after: var(--vel-fixed-spacing-07);
          margin-block-end: var(--vel-fixed-spacing-07);
}
.vel-margin-y-07.vel-margin-y-07 {
  margin-block: var(--vel-fixed-spacing-07);
}
.vel-margin-start-07.vel-margin-start-07 {
  -webkit-margin-start: var(--vel-fixed-spacing-07);
          margin-inline-start: var(--vel-fixed-spacing-07);
}
.vel-margin-end-07.vel-margin-end-07 {
  -webkit-margin-end: var(--vel-fixed-spacing-07);
          margin-inline-end: var(--vel-fixed-spacing-07);
}
.vel-margin-x-07.vel-margin-x-07 {
  margin-inline: var(--vel-fixed-spacing-07);
}
.vel-margin-around-07.vel-margin-around-07 {
  margin: var(--vel-fixed-spacing-07);
}
.vel-margin-top-08.vel-margin-top-08 {
  -webkit-margin-before: var(--vel-fixed-spacing-08);
          margin-block-start: var(--vel-fixed-spacing-08);
}
.vel-margin-bottom-08.vel-margin-bottom-08 {
  -webkit-margin-after: var(--vel-fixed-spacing-08);
          margin-block-end: var(--vel-fixed-spacing-08);
}
.vel-margin-y-08.vel-margin-y-08 {
  margin-block: var(--vel-fixed-spacing-08);
}
.vel-margin-start-08.vel-margin-start-08 {
  -webkit-margin-start: var(--vel-fixed-spacing-08);
          margin-inline-start: var(--vel-fixed-spacing-08);
}
.vel-margin-end-08.vel-margin-end-08 {
  -webkit-margin-end: var(--vel-fixed-spacing-08);
          margin-inline-end: var(--vel-fixed-spacing-08);
}
.vel-margin-x-08.vel-margin-x-08 {
  margin-inline: var(--vel-fixed-spacing-08);
}
.vel-margin-around-08.vel-margin-around-08 {
  margin: var(--vel-fixed-spacing-08);
}
.vel-margin-top-09.vel-margin-top-09 {
  -webkit-margin-before: var(--vel-fixed-spacing-09);
          margin-block-start: var(--vel-fixed-spacing-09);
}
.vel-margin-bottom-09.vel-margin-bottom-09 {
  -webkit-margin-after: var(--vel-fixed-spacing-09);
          margin-block-end: var(--vel-fixed-spacing-09);
}
.vel-margin-y-09.vel-margin-y-09 {
  margin-block: var(--vel-fixed-spacing-09);
}
.vel-margin-start-09.vel-margin-start-09 {
  -webkit-margin-start: var(--vel-fixed-spacing-09);
          margin-inline-start: var(--vel-fixed-spacing-09);
}
.vel-margin-end-09.vel-margin-end-09 {
  -webkit-margin-end: var(--vel-fixed-spacing-09);
          margin-inline-end: var(--vel-fixed-spacing-09);
}
.vel-margin-x-09.vel-margin-x-09 {
  margin-inline: var(--vel-fixed-spacing-09);
}
.vel-margin-around-09.vel-margin-around-09 {
  margin: var(--vel-fixed-spacing-09);
}
.vel-layout-margin-top-01.vel-layout-margin-top-01 {
  -webkit-margin-before: var(--vel-layout-spacing-01);
          margin-block-start: var(--vel-layout-spacing-01);
}
.vel-layout-margin-bottom-01.vel-layout-margin-bottom-01 {
  -webkit-margin-after: var(--vel-layout-spacing-01);
          margin-block-end: var(--vel-layout-spacing-01);
}
.vel-layout-margin-y-01.vel-layout-margin-y-01 {
  margin-block: var(--vel-layout-spacing-01);
}
.vel-layout-margin-start-01.vel-layout-margin-start-01 {
  -webkit-margin-start: var(--vel-layout-spacing-01);
          margin-inline-start: var(--vel-layout-spacing-01);
}
.vel-layout-margin-end-01.vel-layout-margin-end-01 {
  -webkit-margin-end: var(--vel-layout-spacing-01);
          margin-inline-end: var(--vel-layout-spacing-01);
}
.vel-layout-margin-x-01.vel-layout-margin-x-01 {
  margin-inline: var(--vel-layout-spacing-01);
}
.vel-layout-margin-around-01.vel-layout-margin-around-01 {
  margin: var(--vel-layout-spacing-01);
}
.vel-layout-margin-top-02.vel-layout-margin-top-02 {
  -webkit-margin-before: var(--vel-layout-spacing-02);
          margin-block-start: var(--vel-layout-spacing-02);
}
.vel-layout-margin-bottom-02.vel-layout-margin-bottom-02 {
  -webkit-margin-after: var(--vel-layout-spacing-02);
          margin-block-end: var(--vel-layout-spacing-02);
}
.vel-layout-margin-y-02.vel-layout-margin-y-02 {
  margin-block: var(--vel-layout-spacing-02);
}
.vel-layout-margin-start-02.vel-layout-margin-start-02 {
  -webkit-margin-start: var(--vel-layout-spacing-02);
          margin-inline-start: var(--vel-layout-spacing-02);
}
.vel-layout-margin-end-02.vel-layout-margin-end-02 {
  -webkit-margin-end: var(--vel-layout-spacing-02);
          margin-inline-end: var(--vel-layout-spacing-02);
}
.vel-layout-margin-x-02.vel-layout-margin-x-02 {
  margin-inline: var(--vel-layout-spacing-02);
}
.vel-layout-margin-around-02.vel-layout-margin-around-02 {
  margin: var(--vel-layout-spacing-02);
}
.vel-layout-margin-top-03.vel-layout-margin-top-03 {
  -webkit-margin-before: var(--vel-layout-spacing-03);
          margin-block-start: var(--vel-layout-spacing-03);
}
.vel-layout-margin-bottom-03.vel-layout-margin-bottom-03 {
  -webkit-margin-after: var(--vel-layout-spacing-03);
          margin-block-end: var(--vel-layout-spacing-03);
}
.vel-layout-margin-y-03.vel-layout-margin-y-03 {
  margin-block: var(--vel-layout-spacing-03);
}
.vel-layout-margin-start-03.vel-layout-margin-start-03 {
  -webkit-margin-start: var(--vel-layout-spacing-03);
          margin-inline-start: var(--vel-layout-spacing-03);
}
.vel-layout-margin-end-03.vel-layout-margin-end-03 {
  -webkit-margin-end: var(--vel-layout-spacing-03);
          margin-inline-end: var(--vel-layout-spacing-03);
}
.vel-layout-margin-x-03.vel-layout-margin-x-03 {
  margin-inline: var(--vel-layout-spacing-03);
}
.vel-layout-margin-around-03.vel-layout-margin-around-03 {
  margin: var(--vel-layout-spacing-03);
}
.vel-layout-margin-top-04.vel-layout-margin-top-04 {
  -webkit-margin-before: var(--vel-layout-spacing-04);
          margin-block-start: var(--vel-layout-spacing-04);
}
.vel-layout-margin-bottom-04.vel-layout-margin-bottom-04 {
  -webkit-margin-after: var(--vel-layout-spacing-04);
          margin-block-end: var(--vel-layout-spacing-04);
}
.vel-layout-margin-y-04.vel-layout-margin-y-04 {
  margin-block: var(--vel-layout-spacing-04);
}
.vel-layout-margin-start-04.vel-layout-margin-start-04 {
  -webkit-margin-start: var(--vel-layout-spacing-04);
          margin-inline-start: var(--vel-layout-spacing-04);
}
.vel-layout-margin-end-04.vel-layout-margin-end-04 {
  -webkit-margin-end: var(--vel-layout-spacing-04);
          margin-inline-end: var(--vel-layout-spacing-04);
}
.vel-layout-margin-x-04.vel-layout-margin-x-04 {
  margin-inline: var(--vel-layout-spacing-04);
}
.vel-layout-margin-around-04.vel-layout-margin-around-04 {
  margin: var(--vel-layout-spacing-04);
}
.vel-layout-margin-top-05.vel-layout-margin-top-05 {
  -webkit-margin-before: var(--vel-layout-spacing-05);
          margin-block-start: var(--vel-layout-spacing-05);
}
.vel-layout-margin-bottom-05.vel-layout-margin-bottom-05 {
  -webkit-margin-after: var(--vel-layout-spacing-05);
          margin-block-end: var(--vel-layout-spacing-05);
}
.vel-layout-margin-y-05.vel-layout-margin-y-05 {
  margin-block: var(--vel-layout-spacing-05);
}
.vel-layout-margin-start-05.vel-layout-margin-start-05 {
  -webkit-margin-start: var(--vel-layout-spacing-05);
          margin-inline-start: var(--vel-layout-spacing-05);
}
.vel-layout-margin-end-05.vel-layout-margin-end-05 {
  -webkit-margin-end: var(--vel-layout-spacing-05);
          margin-inline-end: var(--vel-layout-spacing-05);
}
.vel-layout-margin-x-05.vel-layout-margin-x-05 {
  margin-inline: var(--vel-layout-spacing-05);
}
.vel-layout-margin-around-05.vel-layout-margin-around-05 {
  margin: var(--vel-layout-spacing-05);
}
.vel-layout-margin-top-06.vel-layout-margin-top-06 {
  -webkit-margin-before: var(--vel-layout-spacing-06);
          margin-block-start: var(--vel-layout-spacing-06);
}
.vel-layout-margin-bottom-06.vel-layout-margin-bottom-06 {
  -webkit-margin-after: var(--vel-layout-spacing-06);
          margin-block-end: var(--vel-layout-spacing-06);
}
.vel-layout-margin-y-06.vel-layout-margin-y-06 {
  margin-block: var(--vel-layout-spacing-06);
}
.vel-layout-margin-start-06.vel-layout-margin-start-06 {
  -webkit-margin-start: var(--vel-layout-spacing-06);
          margin-inline-start: var(--vel-layout-spacing-06);
}
.vel-layout-margin-end-06.vel-layout-margin-end-06 {
  -webkit-margin-end: var(--vel-layout-spacing-06);
          margin-inline-end: var(--vel-layout-spacing-06);
}
.vel-layout-margin-x-06.vel-layout-margin-x-06 {
  margin-inline: var(--vel-layout-spacing-06);
}
.vel-layout-margin-around-06.vel-layout-margin-around-06 {
  margin: var(--vel-layout-spacing-06);
}
.vel-layout-margin-top-07.vel-layout-margin-top-07 {
  -webkit-margin-before: var(--vel-layout-spacing-07);
          margin-block-start: var(--vel-layout-spacing-07);
}
.vel-layout-margin-bottom-07.vel-layout-margin-bottom-07 {
  -webkit-margin-after: var(--vel-layout-spacing-07);
          margin-block-end: var(--vel-layout-spacing-07);
}
.vel-layout-margin-y-07.vel-layout-margin-y-07 {
  margin-block: var(--vel-layout-spacing-07);
}
.vel-layout-margin-start-07.vel-layout-margin-start-07 {
  -webkit-margin-start: var(--vel-layout-spacing-07);
          margin-inline-start: var(--vel-layout-spacing-07);
}
.vel-layout-margin-end-07.vel-layout-margin-end-07 {
  -webkit-margin-end: var(--vel-layout-spacing-07);
          margin-inline-end: var(--vel-layout-spacing-07);
}
.vel-layout-margin-x-07.vel-layout-margin-x-07 {
  margin-inline: var(--vel-layout-spacing-07);
}
.vel-layout-margin-around-07.vel-layout-margin-around-07 {
  margin: var(--vel-layout-spacing-07);
}
.vel-layout-margin-top-08.vel-layout-margin-top-08 {
  -webkit-margin-before: var(--vel-layout-spacing-08);
          margin-block-start: var(--vel-layout-spacing-08);
}
.vel-layout-margin-bottom-08.vel-layout-margin-bottom-08 {
  -webkit-margin-after: var(--vel-layout-spacing-08);
          margin-block-end: var(--vel-layout-spacing-08);
}
.vel-layout-margin-y-08.vel-layout-margin-y-08 {
  margin-block: var(--vel-layout-spacing-08);
}
.vel-layout-margin-start-08.vel-layout-margin-start-08 {
  -webkit-margin-start: var(--vel-layout-spacing-08);
          margin-inline-start: var(--vel-layout-spacing-08);
}
.vel-layout-margin-end-08.vel-layout-margin-end-08 {
  -webkit-margin-end: var(--vel-layout-spacing-08);
          margin-inline-end: var(--vel-layout-spacing-08);
}
.vel-layout-margin-x-08.vel-layout-margin-x-08 {
  margin-inline: var(--vel-layout-spacing-08);
}
.vel-layout-margin-around-08.vel-layout-margin-around-08 {
  margin: var(--vel-layout-spacing-08);
}
:root {
  --vel-color-primary-500-raw: 0, 98, 98;
  --vel-color-primary-400-raw: 0, 130, 130;
  --vel-color-primary-300-raw: 51, 155, 155;
  --vel-color-primary-200-raw: 102, 180, 180;
  --vel-color-primary-100-raw: 153, 205, 205;
  --vel-color-primary-50-raw: 227, 246, 246;
  --vel-color-primary-contrast-500-raw: 255, 255, 255;
  --vel-color-primary-contrast-400-raw: 255, 255, 255;
  --vel-color-primary-contrast-300-raw: 0, 0, 0;
  --vel-color-primary-contrast-200-raw: 45, 45, 45;
  --vel-color-primary-contrast-100-raw: 45, 45, 45;
  --vel-color-primary-contrast-50-raw: 45, 45, 45;
  --vel-color-secondary-500-raw: 7, 37, 52;
  --vel-color-secondary-400-raw: 15, 53, 73;
  --vel-color-secondary-300-raw: 63, 93, 109;
  --vel-color-secondary-200-raw: 111, 134, 146;
  --vel-color-secondary-100-raw: 159, 174, 182;
  --vel-color-secondary-50-raw: 231, 235, 237;
  --vel-color-secondary-contrast-500-raw: 255, 255, 255;
  --vel-color-secondary-contrast-400-raw: 255, 255, 255;
  --vel-color-secondary-contrast-300-raw: 255, 255, 255;
  --vel-color-secondary-contrast-200-raw: 0, 0, 0;
  --vel-color-secondary-contrast-100-raw: 45, 45, 45;
  --vel-color-secondary-contrast-50-raw: 45, 45, 45;
  --vel-color-brand-support1-500-raw: 255, 201, 5;
  --vel-color-brand-support1-400-raw: 255, 213, 64;
  --vel-color-brand-support1-300-raw: 255, 226, 121;
  --vel-color-brand-support1-200-raw: 255, 234, 159;
  --vel-color-brand-support1-100-raw: 255, 243, 198;
  --vel-color-brand-support1-50-raw: 255, 249, 226;
  --vel-color-brand-support1-contrast-500-raw: 15, 53, 73;
  --vel-color-brand-support1-contrast-400-raw: 15, 53, 73;
  --vel-color-brand-support1-contrast-300-raw: 15, 53, 73;
  --vel-color-brand-support1-contrast-200-raw: 15, 53, 73;
  --vel-color-brand-support1-contrast-100-raw: 15, 53, 73;
  --vel-color-brand-support1-contrast-50-raw: 15, 53, 73;
  --vel-color-brand-support2-500-raw: 59, 180, 180;
  --vel-color-brand-support2-400-raw: 115, 210, 210;
  --vel-color-brand-support2-300-raw: 157, 224, 224;
  --vel-color-brand-support2-200-raw: 185, 233, 233;
  --vel-color-brand-support2-100-raw: 213, 241, 241;
  --vel-color-brand-support2-50-raw: 234, 248, 248;
  --vel-color-brand-support2-contrast-500-raw: 15, 53, 73;
  --vel-color-brand-support2-contrast-400-raw: 15, 53, 73;
  --vel-color-brand-support2-contrast-300-raw: 15, 53, 73;
  --vel-color-brand-support2-contrast-200-raw: 15, 53, 73;
  --vel-color-brand-support2-contrast-100-raw: 15, 53, 73;
  --vel-color-brand-support2-contrast-50-raw: 15, 53, 73;
  --vel-color-brand-support3-500-raw: 0, 90, 156;
  --vel-color-brand-support3-400-raw: 0, 115, 200;
  --vel-color-brand-support3-300-raw: 64, 150, 214;
  --vel-color-brand-support3-200-raw: 128, 185, 227;
  --vel-color-brand-support3-100-raw: 178, 213, 239;
  --vel-color-brand-support3-50-raw: 229, 241, 249;
  --vel-color-brand-support3-contrast-500-raw: 255, 255, 255;
  --vel-color-brand-support3-contrast-400-raw: 255, 255, 255;
  --vel-color-brand-support3-contrast-300-raw: 0, 0, 0;
  --vel-color-brand-support3-contrast-200-raw: 45, 45, 45;
  --vel-color-brand-support3-contrast-100-raw: 45, 45, 45;
  --vel-color-brand-support3-contrast-50-raw: 45, 45, 45;
  --vel-color-neutral-900-raw: 0, 0, 0;
  --vel-color-neutral-800-raw: 45, 45, 45;
  --vel-color-neutral-700-raw: 84, 86, 86;
  --vel-color-neutral-600-raw: 117, 119, 119;
  --vel-color-neutral-500-raw: 152, 154, 154;
  --vel-color-neutral-400-raw: 187, 187, 187;
  --vel-color-neutral-300-raw: 221, 221, 221;
  --vel-color-neutral-200-raw: 238, 238, 238;
  --vel-color-neutral-100-raw: 246, 246, 246;
  --vel-color-neutral-white-raw: 255, 255, 255;
  --vel-color-neutral-contrast-900-raw: 255, 255, 255;
  --vel-color-neutral-contrast-800-raw: 255, 255, 255;
  --vel-color-neutral-contrast-700-raw: 255, 255, 255;
  --vel-color-neutral-contrast-600-raw: 255, 255, 255;
  --vel-color-neutral-contrast-500-raw: 45, 45, 45;
  --vel-color-neutral-contrast-400-raw: 45, 45, 45;
  --vel-color-neutral-contrast-300-raw: 45, 45, 45;
  --vel-color-neutral-contrast-200-raw: 45, 45, 45;
  --vel-color-neutral-contrast-100-raw: 45, 45, 45;
  --vel-color-neutral-contrast-white-raw: 45, 45, 45;
  --vel-color-background-main-raw: 255, 255, 255;
  --vel-color-background-alt-raw: 246, 246, 246;
  --vel-color-semantic-error-high-raw: 207, 53, 22;
  --vel-color-semantic-error-low-raw: 254, 244, 244;
  --vel-color-semantic-error-contrast-high-raw: 255, 255, 255;
  --vel-color-semantic-error-contrast-low-raw: 45, 45, 45;
  --vel-color-semantic-success-high-raw: 6, 116, 23;
  --vel-color-semantic-success-low-raw: 237, 248, 238;
  --vel-color-semantic-success-contrast-high-raw: 255, 255, 255;
  --vel-color-semantic-success-contrast-low-raw: 45, 45, 45;
  --vel-color-semantic-warning-high-raw: 215, 116, 0;
  --vel-color-semantic-warning-low-raw: 255, 249, 237;
  --vel-color-semantic-warning-contrast-high-raw: 0, 0, 0;
  --vel-color-semantic-warning-contrast-low-raw: 45, 45, 45;
  --vel-color-semantic-info-high-raw: 28, 104, 202;
  --vel-color-semantic-info-low-raw: 240, 246, 255;
  --vel-color-semantic-info-contrast-high-raw: 255, 255, 255;
  --vel-color-semantic-info-contrast-low-raw: 45, 45, 45;
  --vel-color-misc-focused-raw: 0, 101, 255;
  --vel-color-misc-pressed-raw: 69, 143, 255, 0.25;
  --vel-color-text-headlines-raw: 15, 53, 73;
  --vel-color-text-default-raw: 15, 53, 73;
  --vel-color-text-on_primary-raw: 255, 255, 255;
  --vel-color-text-on_secondary-raw: 255, 255, 255;
  --vel-color-text-on_brand_support1-raw: 15, 53, 73;
  --vel-color-text-on_brand_support2-raw: 15, 53, 73;
  --vel-color-text-on_brand_support3-raw: 255, 255, 255;
  --vel-color-primary-500: rgb(var(--vel-color-primary-500-raw));
  --vel-color-primary-400: rgb(var(--vel-color-primary-400-raw));
  --vel-color-primary-300: rgb(var(--vel-color-primary-300-raw));
  --vel-color-primary-200: rgb(var(--vel-color-primary-200-raw));
  --vel-color-primary-100: rgb(var(--vel-color-primary-100-raw));
  --vel-color-primary-50: rgb(var(--vel-color-primary-50-raw));
  --vel-color-primary-contrast-500: rgb(
    var(--vel-color-primary-contrast-500-raw)
  );
  --vel-color-primary-contrast-400: rgb(
    var(--vel-color-primary-contrast-400-raw)
  );
  --vel-color-primary-contrast-300: rgb(
    var(--vel-color-primary-contrast-300-raw)
  );
  --vel-color-primary-contrast-200: rgb(
    var(--vel-color-primary-contrast-200-raw)
  );
  --vel-color-primary-contrast-100: rgb(
    var(--vel-color-primary-contrast-100-raw)
  );
  --vel-color-primary-contrast-50: rgb(
    var(--vel-color-primary-contrast-50-raw)
  );
  --vel-color-secondary-500: rgb(var(--vel-color-secondary-500-raw));
  --vel-color-secondary-400: rgb(var(--vel-color-secondary-400-raw));
  --vel-color-secondary-300: rgb(var(--vel-color-secondary-300-raw));
  --vel-color-secondary-200: rgb(var(--vel-color-secondary-200-raw));
  --vel-color-secondary-100: rgb(var(--vel-color-secondary-100-raw));
  --vel-color-secondary-50: rgb(var(--vel-color-secondary-50-raw));
  --vel-color-secondary-contrast-500: rgb(
    var(--vel-color-secondary-contrast-500-raw)
  );
  --vel-color-secondary-contrast-400: rgb(
    var(--vel-color-secondary-contrast-400-raw)
  );
  --vel-color-secondary-contrast-300: rgb(
    var(--vel-color-secondary-contrast-300-raw)
  );
  --vel-color-secondary-contrast-200: rgb(
    var(--vel-color-secondary-contrast-200-raw)
  );
  --vel-color-secondary-contrast-100: rgb(
    var(--vel-color-secondary-contrast-100-raw)
  );
  --vel-color-secondary-contrast-50: rgb(
    var(--vel-color-secondary-contrast-50-raw)
  );
  --vel-color-brand-support1-500: rgb(var(--vel-color-brand-support1-500-raw));
  --vel-color-brand-support1-400: rgb(var(--vel-color-brand-support1-400-raw));
  --vel-color-brand-support1-300: rgb(var(--vel-color-brand-support1-300-raw));
  --vel-color-brand-support1-200: rgb(var(--vel-color-brand-support1-200-raw));
  --vel-color-brand-support1-100: rgb(var(--vel-color-brand-support1-100-raw));
  --vel-color-brand-support1-50: rgb(var(--vel-color-brand-support1-50-raw));
  --vel-color-brand-support1-contrast-500: rgb(
    var(--vel-color-brand-support1-contrast-500-raw)
  );
  --vel-color-brand-support1-contrast-400: rgb(
    var(--vel-color-brand-support1-contrast-400-raw)
  );
  --vel-color-brand-support1-contrast-300: rgb(
    var(--vel-color-brand-support1-contrast-300-raw)
  );
  --vel-color-brand-support1-contrast-200: rgb(
    var(--vel-color-brand-support1-contrast-200-raw)
  );
  --vel-color-brand-support1-contrast-100: rgb(
    var(--vel-color-brand-support1-contrast-100-raw)
  );
  --vel-color-brand-support1-contrast-50: rgb(
    var(--vel-color-brand-support1-contrast-50-raw)
  );
  --vel-color-brand-support2-500: rgb(var(--vel-color-brand-support2-500-raw));
  --vel-color-brand-support2-400: rgb(var(--vel-color-brand-support2-400-raw));
  --vel-color-brand-support2-300: rgb(var(--vel-color-brand-support2-300-raw));
  --vel-color-brand-support2-200: rgb(var(--vel-color-brand-support2-200-raw));
  --vel-color-brand-support2-100: rgb(var(--vel-color-brand-support2-100-raw));
  --vel-color-brand-support2-50: rgb(var(--vel-color-brand-support2-50-raw));
  --vel-color-brand-support2-contrast-500: rgb(
    var(--vel-color-brand-support2-contrast-500-raw)
  );
  --vel-color-brand-support2-contrast-400: rgb(
    var(--vel-color-brand-support2-contrast-400-raw)
  );
  --vel-color-brand-support2-contrast-300: rgb(
    var(--vel-color-brand-support2-contrast-300-raw)
  );
  --vel-color-brand-support2-contrast-200: rgb(
    var(--vel-color-brand-support2-contrast-200-raw)
  );
  --vel-color-brand-support2-contrast-100: rgb(
    var(--vel-color-brand-support2-contrast-100-raw)
  );
  --vel-color-brand-support2-contrast-50: rgb(
    var(--vel-color-brand-support2-contrast-50-raw)
  );
  --vel-color-brand-support3-500: rgb(var(--vel-color-brand-support3-500-raw));
  --vel-color-brand-support3-400: rgb(var(--vel-color-brand-support3-400-raw));
  --vel-color-brand-support3-300: rgb(var(--vel-color-brand-support3-300-raw));
  --vel-color-brand-support3-200: rgb(var(--vel-color-brand-support3-200-raw));
  --vel-color-brand-support3-100: rgb(var(--vel-color-brand-support3-100-raw));
  --vel-color-brand-support3-50: rgb(var(--vel-color-brand-support3-50-raw));
  --vel-color-brand-support3-contrast-500: rgb(
    var(--vel-color-brand-support3-contrast-500-raw)
  );
  --vel-color-brand-support3-contrast-400: rgb(
    var(--vel-color-brand-support3-contrast-400-raw)
  );
  --vel-color-brand-support3-contrast-300: rgb(
    var(--vel-color-brand-support3-contrast-300-raw)
  );
  --vel-color-brand-support3-contrast-200: rgb(
    var(--vel-color-brand-support3-contrast-200-raw)
  );
  --vel-color-brand-support3-contrast-100: rgb(
    var(--vel-color-brand-support3-contrast-100-raw)
  );
  --vel-color-brand-support3-contrast-50: rgb(
    var(--vel-color-brand-support3-contrast-50-raw)
  );
  --vel-color-neutral-900: rgb(var(--vel-color-neutral-900-raw));
  --vel-color-neutral-800: rgb(var(--vel-color-neutral-800-raw));
  --vel-color-neutral-700: rgb(var(--vel-color-neutral-700-raw));
  --vel-color-neutral-600: rgb(var(--vel-color-neutral-600-raw));
  --vel-color-neutral-500: rgb(var(--vel-color-neutral-500-raw));
  --vel-color-neutral-400: rgb(var(--vel-color-neutral-400-raw));
  --vel-color-neutral-300: rgb(var(--vel-color-neutral-300-raw));
  --vel-color-neutral-200: rgb(var(--vel-color-neutral-200-raw));
  --vel-color-neutral-100: rgb(var(--vel-color-neutral-100-raw));
  --vel-color-neutral-white: rgb(var(--vel-color-neutral-white-raw));
  --vel-color-neutral-contrast-900: rgb(
    var(--vel-color-neutral-contrast-900-raw)
  );
  --vel-color-neutral-contrast-800: rgb(
    var(--vel-color-neutral-contrast-800-raw)
  );
  --vel-color-neutral-contrast-700: rgb(
    var(--vel-color-neutral-contrast-700-raw)
  );
  --vel-color-neutral-contrast-600: rgb(
    var(--vel-color-neutral-contrast-600-raw)
  );
  --vel-color-neutral-contrast-500: rgb(
    var(--vel-color-neutral-contrast-500-raw)
  );
  --vel-color-neutral-contrast-400: rgb(
    var(--vel-color-neutral-contrast-400-raw)
  );
  --vel-color-neutral-contrast-300: rgb(
    var(--vel-color-neutral-contrast-300-raw)
  );
  --vel-color-neutral-contrast-200: rgb(
    var(--vel-color-neutral-contrast-200-raw)
  );
  --vel-color-neutral-contrast-100: rgb(
    var(--vel-color-neutral-contrast-100-raw)
  );
  --vel-color-neutral-contrast-white: rgb(
    var(--vel-color-neutral-contrast-white-raw)
  );
  --vel-color-background-main: rgb(var(--vel-color-background-main-raw));
  --vel-color-background-alt: rgb(var(--vel-color-background-alt-raw));
  --vel-color-semantic-error-high: rgb(
    var(--vel-color-semantic-error-high-raw)
  );
  --vel-color-semantic-error-low: rgb(var(--vel-color-semantic-error-low-raw));
  --vel-color-semantic-error-contrast-high: rgb(
    var(--vel-color-semantic-error-contrast-high-raw)
  );
  --vel-color-semantic-error-contrast-low: rgb(
    var(--vel-color-semantic-error-contrast-low-raw)
  );
  --vel-color-semantic-success-high: rgb(
    var(--vel-color-semantic-success-high-raw)
  );
  --vel-color-semantic-success-low: rgb(
    var(--vel-color-semantic-success-low-raw)
  );
  --vel-color-semantic-success-contrast-high: rgb(
    var(--vel-color-semantic-success-contrast-high-raw)
  );
  --vel-color-semantic-success-contrast-low: rgb(
    var(--vel-color-semantic-success-contrast-low-raw)
  );
  --vel-color-semantic-warning-high: rgb(
    var(--vel-color-semantic-warning-high-raw)
  );
  --vel-color-semantic-warning-low: rgb(
    var(--vel-color-semantic-warning-low-raw)
  );
  --vel-color-semantic-warning-contrast-high: rgb(
    var(--vel-color-semantic-warning-contrast-high-raw)
  );
  --vel-color-semantic-warning-contrast-low: rgb(
    var(--vel-color-semantic-warning-contrast-low-raw)
  );
  --vel-color-semantic-info-high: rgb(var(--vel-color-semantic-info-high-raw));
  --vel-color-semantic-info-low: rgb(var(--vel-color-semantic-info-low-raw));
  --vel-color-semantic-info-contrast-high: rgb(
    var(--vel-color-semantic-info-contrast-high-raw)
  );
  --vel-color-semantic-info-contrast-low: rgb(
    var(--vel-color-semantic-info-contrast-low-raw)
  );
  --vel-color-misc-focused: rgb(var(--vel-color-misc-focused-raw));
  --vel-color-misc-pressed: rgba(var(--vel-color-misc-pressed-raw));
  --vel-color-text-headlines: rgb(var(--vel-color-text-headlines-raw));
  --vel-color-text-default: rgb(var(--vel-color-text-default-raw));
  --vel-color-text-on_primary: rgb(var(--vel-color-text-on_primary-raw));
  --vel-color-text-on_secondary: rgb(var(--vel-color-text-on_secondary-raw));
  --vel-color-text-on_brand_support1: rgb(
    var(--vel-color-text-on_brand_support1-raw)
  );
  --vel-color-text-on_brand_support2: rgb(
    var(--vel-color-text-on_brand_support2-raw)
  );
  --vel-color-text-on_brand_support3: rgb(
    var(--vel-color-text-on_brand_support3-raw)
  );
  --vel-color-form-text-default: var(--vel-color-neutral-800);
  --vel-color-form-text-placeholder: var(--vel-color-neutral-600);
  --vel-color-form-text-disabled: var(--vel-color-neutral-500);
  --vel-color-form-bg-default: var(--vel-color-neutral-white);
  --vel-color-form-bg-disabled: var(--vel-color-neutral-200);
  --vel-color-form-border-default: var(--vel-color-neutral-600);
  --vel-color-form-border-hover: var(--vel-color-neutral-900);
  --vel-color-form-border-disabled: var(--vel-color-neutral-300);
  --vel-color-dividerColor: var(--vel-color-neutral-300);
}
:root {
  --vel-shape100-border_radius: 0px;
  --vel-shape200-border_radius: 2px;
  --vel-shape300-border_radius: 4px;
  --vel-shape400-border_radius: 12px;
  --vel-shape500-border_radius: 999px;
}
.vel-shape100.vel-shape100 {
  border-radius: var(--vel-shape100-border_radius);
}
.vel-shape200.vel-shape200 {
  border-radius: var(--vel-shape200-border_radius);
}
.vel-shape300.vel-shape300 {
  border-radius: var(--vel-shape300-border_radius);
}
.vel-shape400.vel-shape400 {
  border-radius: var(--vel-shape400-border_radius);
}
.vel-shape500.vel-shape500 {
  border-radius: var(--vel-shape500-border_radius);
}
:root {
  --vel-elevation100-box_shadow: 0px 2px 2px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation200-box_shadow: 0px 4px 8px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation300-box_shadow: 0px 8px 12px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation400-box_shadow: 0px 12px 16px rgba(9, 30, 66, 0.12),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation500-box_shadow: 0px 16px 32px rgba(9, 30, 66, 0.16),
    0px 0px 1px rgba(9, 30, 66, 0.2);
}
.vel-elevation100.vel-elevation100 {
  box-shadow: var(--vel-elevation100-box_shadow);
}
.vel-elevation200.vel-elevation200 {
  box-shadow: var(--vel-elevation200-box_shadow);
}
.vel-elevation300.vel-elevation300 {
  box-shadow: var(--vel-elevation300-box_shadow);
}
.vel-elevation400.vel-elevation400 {
  box-shadow: var(--vel-elevation400-box_shadow);
}
.vel-elevation500.vel-elevation500 {
  box-shadow: var(--vel-elevation500-box_shadow);
}
:root {
  --vel-elevation100-filter: drop-shadow(0px 2px 2px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation200-filter: drop-shadow(0px 4px 8px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation300-filter: drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation400-filter: drop-shadow(0px 12px 16px rgba(9, 30, 66, 0.12))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation500-filter: drop-shadow(0px 16px 32px rgba(9, 30, 66, 0.16))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
}
:root {
  --vel-typography-text-50-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-50-font_weight: 400;
  --vel-typography-text-50-letter_spacing: normal;
  --vel-typography-text-50-font_size: 11px;
  --vel-typography-text-50-line_height: 1.4545454545454546;
  --vel-typography-text-50-bold-font_weight: 700;
  --vel-typography-text-50-loose-line_height: 1.8181818181818181;
  --vel-typography-text-100-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-100-font_weight: 400;
  --vel-typography-text-100-letter_spacing: normal;
  --vel-typography-text-100-font_size: 14px;
  --vel-typography-text-100-line_height: 1.4285714285714286;
  --vel-typography-text-100-bold-font_weight: 700;
  --vel-typography-text-100-loose-line_height: 1.7142857142857142;
  --vel-typography-text-200-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-200-font_weight: 400;
  --vel-typography-text-200-letter_spacing: normal;
  --vel-typography-text-200-font_size: 16px;
  --vel-typography-text-200-line_height: 1.25;
  --vel-typography-text-200-bold-font_weight: 700;
  --vel-typography-text-200-loose-line_height: 1.5;
  --vel-typography-text-300-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-300-font_weight: 400;
  --vel-typography-text-300-letter_spacing: normal;
  --vel-typography-text-300-font_size: 18px;
  --vel-typography-text-300-line_height: 1.3333333333333333;
  --vel-typography-text-300-bold-font_weight: 700;
  --vel-typography-text-300-loose-line_height: 1.5555555555555556;
  --vel-typography-text-400-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-400-font_weight: 400;
  --vel-typography-text-400-letter_spacing: normal;
  --vel-typography-text-400-font_size: 23px;
  --vel-typography-text-400-line_height: 1.2173913043478262;
  --vel-typography-text-400-bold-font_weight: 700;
  --vel-typography-text-400-loose-line_height: 1.565217391304348;
  --vel-typography-text-500-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-500-font_weight: 400;
  --vel-typography-text-500-letter_spacing: normal;
  --vel-typography-text-500-font_size: 28px;
  --vel-typography-text-500-line_height: 1.2857142857142858;
  --vel-typography-text-500-bold-font_weight: 700;
  --vel-typography-text-500-loose-line_height: 1.5714285714285714;
  --vel-typography-text-alt-50-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-50-font_weight: 500;
  --vel-typography-text-alt-50-letter_spacing: normal;
  --vel-typography-text-alt-50-font_size: 11px;
  --vel-typography-text-alt-50-line_height: 1.4545454545454546;
  --vel-typography-text-alt-50-bold-font_weight: 600;
  --vel-typography-text-alt-50-loose-line_height: 1.8181818181818181;
  --vel-typography-text-alt-100-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-100-font_weight: 500;
  --vel-typography-text-alt-100-letter_spacing: normal;
  --vel-typography-text-alt-100-font_size: 13px;
  --vel-typography-text-alt-100-line_height: 1.5384615384615385;
  --vel-typography-text-alt-100-bold-font_weight: 600;
  --vel-typography-text-alt-100-loose-line_height: 1.8461538461538463;
  --vel-typography-text-alt-200-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-200-font_weight: 500;
  --vel-typography-text-alt-200-letter_spacing: normal;
  --vel-typography-text-alt-200-font_size: 15px;
  --vel-typography-text-alt-200-line_height: 1.3333333333333333;
  --vel-typography-text-alt-200-bold-font_weight: 600;
  --vel-typography-text-alt-200-loose-line_height: 1.6;
  --vel-typography-text-alt-300-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-300-font_weight: 500;
  --vel-typography-text-alt-300-letter_spacing: normal;
  --vel-typography-text-alt-300-font_size: 17px;
  --vel-typography-text-alt-300-line_height: 1.411764705882353;
  --vel-typography-text-alt-300-bold-font_weight: 600;
  --vel-typography-text-alt-300-loose-line_height: 1.6470588235294117;
  --vel-typography-text-alt-400-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-400-font_weight: 500;
  --vel-typography-text-alt-400-letter_spacing: normal;
  --vel-typography-text-alt-400-font_size: 22px;
  --vel-typography-text-alt-400-line_height: 1.2727272727272727;
  --vel-typography-text-alt-400-bold-font_weight: 600;
  --vel-typography-text-alt-400-loose-line_height: 1.6363636363636365;
  --vel-typography-text-alt-500-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-500-font_weight: 500;
  --vel-typography-text-alt-500-letter_spacing: normal;
  --vel-typography-text-alt-500-font_size: 26px;
  --vel-typography-text-alt-500-line_height: 1.3846153846153846;
  --vel-typography-text-alt-500-bold-font_weight: 600;
  --vel-typography-text-alt-500-loose-line_height: 1.6923076923076923;
  --vel-typography-headline-100-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-100-font_weight: 500;
  --vel-typography-headline-100-letter_spacing: normal;
  --vel-typography-headline-100-font_size: 16px;
  --vel-typography-headline-100-line_height: 1.25;
  --vel-typography-headline-200-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-200-font_weight: 500;
  --vel-typography-headline-200-letter_spacing: normal;
  --vel-typography-headline-200-font_size: 20px;
  --vel-typography-headline-200-line_height: 1.2;
  --vel-typography-headline-300-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-300-font_weight: 500;
  --vel-typography-headline-300-letter_spacing: normal;
  --vel-typography-headline-300-font_size: 24px;
  --vel-typography-headline-300-line_height: 1.3333333333333333;
  --vel-typography-headline-400-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-400-font_weight: 500;
  --vel-typography-headline-400-letter_spacing: normal;
  --vel-typography-headline-400-font_size: 28px;
  --vel-typography-headline-400-line_height: 1.2857142857142858;
  --vel-typography-headline-500-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-500-font_weight: 500;
  --vel-typography-headline-500-letter_spacing: normal;
  --vel-typography-headline-500-font_size: 32px;
  --vel-typography-headline-500-line_height: 1.25;
  --vel-typography-headline-600-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-600-font_weight: 500;
  --vel-typography-headline-600-letter_spacing: normal;
  --vel-typography-headline-600-font_size: 40px;
  --vel-typography-headline-600-line_height: 1.2;
}
@media only screen and (min-width: 740px) {
  :root {
    --vel-typography-headline-100-font_size: 16px;
    --vel-typography-headline-100-line_height: 1.25;
    --vel-typography-headline-200-font_size: 20px;
    --vel-typography-headline-200-line_height: 1.2;
    --vel-typography-headline-300-font_size: 24px;
    --vel-typography-headline-300-line_height: 1.3333333333333333;
    --vel-typography-headline-400-font_size: 32px;
    --vel-typography-headline-400-line_height: 1.25;
    --vel-typography-headline-500-font_size: 44px;
    --vel-typography-headline-500-line_height: 1.2727272727272727;
    --vel-typography-headline-600-font_size: 56px;
    --vel-typography-headline-600-line_height: 1.2857142857142858;
  }
}
@media only screen and (min-width: 1278px) {
  :root {
    --vel-typography-headline-100-font_size: 16px;
    --vel-typography-headline-100-line_height: 1.5;
    --vel-typography-headline-200-font_size: 20px;
    --vel-typography-headline-200-line_height: 1.4;
    --vel-typography-headline-300-font_size: 28px;
    --vel-typography-headline-300-line_height: 1.2857142857142858;
    --vel-typography-headline-400-font_size: 40px;
    --vel-typography-headline-400-line_height: 1.2;
    --vel-typography-headline-500-font_size: 52px;
    --vel-typography-headline-500-line_height: 1.2307692307692308;
    --vel-typography-headline-600-font_size: 68px;
    --vel-typography-headline-600-line_height: 1.1764705882352942;
  }
}
:root {
  --vel-animation-timing-function: ease;
  --vel-animation-timing-small: 100ms;
  --vel-animation-timing-medium: 200ms;
  --vel-animation-timing-large: 300ms;
}

@font-face {
  font-family: 'Chillax';
  src: url('/fonts/Chillax-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Chillax';
  src: url('/fonts/Chillax-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Chillax';
  src: url('/fonts/Chillax-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wsaZejf5HdF8Q.e1dc9e24.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wMaZejf5HdF8Q.ce5675ba.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wwaZejf5HdF8Q.ac590a4a.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wAaZejf5HdF8Q.cbac608c.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wEaZejf5HdF8Q.0ad5d54e.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3w8aZejf5Hc.e7c7dbb6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wIaZejf5HdF8Q.1e1d9f7e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wsaZejf5HdF8Q.e1dc9e24.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wMaZejf5HdF8Q.ce5675ba.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wwaZejf5HdF8Q.ac590a4a.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wAaZejf5HdF8Q.cbac608c.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3wEaZejf5HdF8Q.0ad5d54e.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(/_next/static/media/nwpStKy2OAdR1K-IwhWudF-R3w8aZejf5Hc.e7c7dbb6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* This file is marked as side effect in package.json to be included by SB  */

* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  min-height: 100%;
  background-color: #f6f8fb;
  color: #2d2d2d;
  overflow-y: scroll;
}

html,
body {
  margin: 0;
  min-width: 320px;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ot-sdk-container {
  margin-left: 0 !important;
}

